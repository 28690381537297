
.footer-outher-container{
    height: 240px;
    width: 100vw;
    background-color: #EAEBEB;
    margin-left: calc(-50vw + 600px);
    border-bottom: 25px solid #E30046;
    -webkit-box-shadow: 0 25px 0 #B10E35;
            box-shadow: 0 25px 0 #B10E35;
    margin-bottom: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.footer-info-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 25px;
}

.working-hours-container{
    margin-bottom: 20px;
}

.footer-info-container > div{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.footer-info-container > div > p,
.footer-info-container > div > a{
    padding: 0 10px;
    margin-bottom: 5px;
    font-family: 'PT Sans', sans-serif;
    color: #002F3B !important;
}

.footer-info-container > div > a:hover{
    opacity: .8;
}

.footer-small-text p{
    font-size: 15px;
}

.fotter-shadow-left{
    -webkit-box-shadow: -7px 0 0 -5px #CDCFD0;
            box-shadow: -7px 0 0 -5px #CDCFD0;
}

.red-color-text{
    color: #E50046;
    font-weight: bold;
}

.footer-popup-trigger:hover{
    opacity: .8;
    cursor: pointer;
}

.footer-popup-container{
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
    padding-left: 0 !important;
}

.footer-popup-container .modal-dialog{
    width: 95vw;
    height: 90vh;
    max-width: 800px;
    margin: 0 !important;
}

.popup-list-block{
    margin-left: 15px !important;
}

.modal-title h1{
    font-size: 65px !important;
    color: #B00E35 !important;
    font-family: 'Teko', sans-serif;
}

.modal-header{
    padding-bottom: 10px !important;
    padding-top: 30px !important;
}

.modal-body > .blog-paragraph-blocks a{
    display: inline-block !important;
}

@media all and (max-width: 1200px){
    .footer-outher-container{
        margin-left: 0;
    }
}

@media all and (max-width: 580px){
    .fotter-shadow-left{
        -webkit-box-shadow: none;
                box-shadow: none;
    }
    .working-hours-container{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    .footer-outher-container{
        height: 340px;
    }
    .modal-title h1{
        font-size: 55px !important;
    }
}