.mg-top-40,
.mg-top-40-bottom-title{
    margin-top: -40px;
}

.contact-text-container{
    margin-top: 20px;
}

.contact-small-title{
    color: #E50046;
    font-size: 20px;
    font-family: 'PT Sans', sans-serif;
    margin: 10px 0 0 0;
    font-weight: bold;
}

.sjediste-text p{
    margin: 0;
    line-height: 20px;
    margin: 10px 0;
}

.sjediste-text strong{
    color: #E50046;
    font-size: 22px;
}

.podaci-o-firmi-text{
    font-size: 14px;
    margin-top: 20px;
}

.form-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.form-container label{
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
}

.form-container input,
.form-container textarea{
    padding: 15px;
    background-color: rgba(150, 153, 157, .5);
    border: none;
    outline: none;
    border-radius: 10px;
    resize: none;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.input-error-message{
    margin-bottom: 20px;
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    margin-left: 5px;
    color: #E50046;
    opacity: 0;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.form-container textarea{
    height: 240px;
}

.form-button{
    width: 100%;
    max-width: 400px;
    padding: 5px 40px;
    color: white;
    border-radius: 50px;
    border: none;
    outline: none;
    font-size: 36px;
    font-family: 'Teko', sans-serif;
    background: rgb(177,14,53);
    background: -o-linear-gradient(bottom, rgba(177,14,53,1) 0%, rgba(228,0,70,1) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(177,14,53,1)), to(rgba(228,0,70,1)));
    background: linear-gradient(0deg, rgba(177,14,53,1) 0%, rgba(228,0,70,1) 100%);
    margin-top: 15px;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.form-button:hover{
    background: -o-linear-gradient(bottom, #C7083C 0%, #C7083C 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(#C7083C), to(#C7083C));
    background: linear-gradient(0deg, #C7083C 0%, #C7083C 100%);
}

.google-maps-container{
    max-height: 400px;
    max-width: 400px;
    height: 800px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 0 10px #CDCFD0;
            box-shadow: 0 0 0 10px #CDCFD0;
    margin-bottom: 40px;
}

.kontaktiraj-nas-container{
    padding-left: 50px;
}

.contact-container{
    margin-bottom: 60px;
}

.error-message-display{
    opacity: 1 !important;
}

.input-error-outline{
    -webkit-box-shadow: 0 0 0 2px #E50046 !important;
            box-shadow: 0 0 0 2px #E50046 !important;
}

.message-sent-container{
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 15px 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    background-color: rgba(145, 255, 175, .95);
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
    margin-left: calc(-50vw + 600px);
    /* -webkit-transform: translateZ(0); */
}

.message-sent-container p{
    margin: 0;
    font-family: 'PT Sans', sans-serif;
}

.message-hidden{
    -webkit-transform: translateY(60px);
        -ms-transform: translateY(60px);
            transform: translateY(60px);
}

.message-sent-container div:hover{
    opacity: .8;
    cursor: pointer;
}

.message-invalid{
    background-color: rgba(255, 138, 138, .9)
}

@media all and (max-width: 1200px){
    .message-sent-container{
        margin-left: 0;
    }
}

@media all and (max-width: 980px){
    .mg-top-40-bottom-title{
        margin-top: 40px;
    }
    .kontaktiraj-nas-container{
        padding-left: 15px;
    }
}

@media all and (max-width: 576px){
    .google-maps-container{
        width: 95%;
        margin: 0 auto 40px auto;
    }
}