.linked-info-outher-container{
    margin-top: 150px;
}

/* .linked-info-text-container{
    height: 250px;
} */

.linked-info-text-container-bg{
    height: 100%;
    width: 100vw;
}

.linked-info-text-outher-container{
    width: 100vw;
    background-color: #EAEBEB;
    margin-left: calc(-50vw + 600px);
    border-bottom: 30px solid #CDCFD0;
}

.linked-info-text-image{
    height: 250px;
    width: 310px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.linked-info-text-image img{
    position: relative;
    bottom: 60px;
}

.linked-info-text-item{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.linked-info-text-item > h1{
    font-size: 80px;
    color: #B00E35;
    margin: 0 0 -18px 0;
    line-height: 1.2 !important;
}

.linked-info-text-item h3{
    font-size: 36px;
    color: #96999D;
    margin: 0;
    text-align: center;
    line-height: 1.2 !important;
}

.vertical-line{
    height: 200px;
    position: relative;
    top: 26px;
    border-left: 2px solid rgba(90, 92, 90, .3);
    -webkit-transform: rotate(15deg);
        -ms-transform: rotate(15deg);
            transform: rotate(15deg);
}

.linked-info-featured-image{
    /* margin-bottom: 40px; */
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    overflow: hidden;
    margin-bottom: -30px;
    position: relative;
    -webkit-box-shadow: 0 75px 21px -60px rgba(0, 0, 0, .4);
            box-shadow: 0 75px 21px -60px rgba(0, 0, 0, .4);
}

.linked-info-featured-image img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    min-height: 400px;
}

.linked-info-text-item-border-top{
    border-top: none;
}

.linked-info-featured-image-button-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.linked-info-featured-image-button-container a{
    padding: 10px 80px;
    background: rgb(177,14,53);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(177,14,53,1)), to(rgba(211,2,65,1)));
    background: -o-linear-gradient(bottom, rgba(177,14,53,1) 0%, rgba(211,2,65,1) 100%);
    background: linear-gradient(0deg, rgba(177,14,53,1) 0%, rgba(211,2,65,1) 100%);
    color: #FFF;
    border-radius: 50px;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.linked-info-featured-image-button-container a:hover{
    background: rgb(177,14,53);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(177,14,53,1)), to(rgba(177,14,53,1)));
    background: -o-linear-gradient(bottom, rgba(177,14,53,1) 0%, rgba(177,14,53,1) 100%);
    background: linear-gradient(0deg, rgba(177,14,53,1) 0%, rgba(177,14,53,1) 100%);
    color: #FFF !important;
}

@media all and (max-width: 1200px){
    .linked-info-text-outher-container{
        margin-left: 0;
    }
    .linked-info-text-image{
        -webkit-box-align: end !important;
            -ms-flex-align: end !important;
                align-items: flex-end !important;
    }
    .linked-info-featured-image{
        width: 100vw;
        margin: 0 auto -30px auto;
    }
    .linked-info-text-image img{
        left: 40px;
    }
}

@media all and (max-width: 991px){
    .vertical-line{
        top: 0;
    }
    .linked-info-text-container{
        max-width: 100vw !important;
    }
    .linked-info-text-image{
        -webkit-box-align: center !important;
            -ms-flex-align: center !important;
                align-items: center !important;
    }
    .linked-info-featured-image-button-container a{
        padding: 10px 40px;
    }
}

@media all and (max-width: 767px){
    .linked-info-text-item-border-top{
        border-top: 2px solid rgba(90, 92, 90, .3);
    }
}

@media all and (max-width: 550px){
    .linked-info-featured-image{
        height: 300px;
    }
    .linked-info-text-image img{
        transform: scale(.8);
    }
}