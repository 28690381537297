.navbar-outher-container {
	width: 100vw;
	position: fixed !important;
	top: 0;
	left: 0;
	z-index: 999;
}

.navbar-container {
	width: 100%;
	max-width: 1200px;
	height: 130px;
	margin: 0 auto;
	padding: 0 15px;
	background-color: transparent !important;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr auto 1fr;
	grid-template-columns: 1fr auto 1fr;
	-ms-flex-line-pack: center;
	align-content: center;
	justify-items: start;
}

.navbar-bg {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	height: 130px;
	width: 100vw;
	z-index: 50;
	border-bottom: 5px solid #e50046;
}

.navbar-navigation-container {
	z-index: 50;
}

.navbar-logo {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin-top: 60px;
	padding: 10px;
	background-color: #fff;
	border-radius: 50%;
	/* flex-direction: column; */
	z-index: 50;
}

.navbar-logo img:hover {
	cursor: pointer;
}

.navbar-logo img {
	position: relative;
	z-index: 50;
}

.nav-logo-border {
	position: absolute;
	width: 174px;
	height: 174px;
	/* background-color: red; */
	margin-top: 0px;
	border-radius: 50%;
	-webkit-box-shadow: 0 0 0 5px #e50046;
	box-shadow: 0 0 0 5px #e50046;
}

.nav-logo-overlay-eraser {
	position: fixed;
	top: 0;
	left: 100px;
	height: 125px;
	width: 100vw;
	background-color: #fff;
	z-index: 25;
}

.nav-link {
	color: #002f3b !important;
	padding: 10px 15px !important;
}

.navbar {
	height: 130px;
	top: 57px;
	padding-left: 0 !important;
	z-index: 51;
	padding-right: 0 !important;
	margin-right: -20px;
}

.navbar-right-info-container {
	z-index: 50;
	height: 130px;
	width: 100%;
	min-width: -webkit-max-content;
	min-width: -moz-max-content;
	min-width: max-content;
	position: relative;
	top: 57px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 10px;
}

.navbar-right-info p {
	margin: 0;
	color: #002f3b;
}

.dropdown-menu {
	border: none !important;
	background-color: #e50046 !important;
}

.dropdown-item {
	font-size: 22px;
	padding-right: 100px !important;
	color: #fff !important;
	-webkit-transition: 300ms;
	-o-transition: 300ms;
	transition: 300ms;
}

.dropdown-item:hover {
	background-color: #bd0a39 !important;
}

.dropdown-toggle::after {
	-webkit-transform: translateY(6px);
	-ms-transform: translateY(6px);
	transform: translateY(6px);
	color: rgba(150, 153, 157, 0.5) !important;
}

.navbar-right-info-icon {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.navbar-right-info-highlighted {
	color: #e50046;
	font-size: 22px;
}

.navbar-right-info-inner-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.active {
	background-color: none !important;
}

.dropdown-item.active {
	background-color: #e50046 !important;
}

.dropdown-item.active:hover {
	background-color: #bd0a39 !important;
}

.navbar-border-left {
	-webkit-box-shadow: -14px 0 0 -12px rgba(150, 153, 157, 0.5);
	box-shadow: -14px 0 0 -12px rgba(150, 153, 157, 0.5);
}

.nav-link:hover {
	opacity: 0.8;
}

.flags {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.flags .flag {
	margin: 5px;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eaebeb;
	border-radius: 2px;
}

.flags .flag:nth-child(2) {
	background-color: rgba(227, 0, 70, 0.4);
}

.flags .flag:first-child {
	cursor: pointer;
}

.flags .flag img {
	height: 20px;
	width: 40px;
	object-fit: contain;
}

@media all and (max-width: 990px) {
	.navbar-collapse {
		position: absolute !important;
		top: 130px;
		left: -15px;
	}
	.nav-link {
		padding-left: 20px !important;
		width: 100vw;
		color: #fff !important;
		background-color: #e50046;
		-webkit-transition: 300ms;
		-o-transition: 300ms;
		transition: 300ms;
	}
	.navbar {
		z-index: 50;
	}
	.nav-link:hover {
		background-color: #bd0a39 !important;
		opacity: 1;
	}
	.dropdown-menu {
		border-radius: 0 !important;
	}
	.navbar-border-left {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.dropdown-toggle::after {
		color: rgba(255, 255, 255, 0.5) !important;
	}
}

@media all and (max-width: 800px) {
	.navbar-logo {
		-webkit-transform: scale(0.7);
		-ms-transform: scale(0.7);
		transform: scale(0.7);
	}
	.nav-logo-overlay-eraser {
		height: 137px;
		top: -2px;
		left: -10px;
		width: 70vw;
	}
	.nav-logo-border {
		-webkit-box-shadow: 0 0 0 7px #e50046;
		box-shadow: 0 0 0 7px #e50046;
	}

	.navbar-right-info-container {
		position: fixed;
		left: 0;
		top: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		z-index: 40;
		padding: 0;
		width: 100vw;
	}
	.navbar-right-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-right-info p {
		margin-right: 10px;
	}
	.navbar-right-info-inner-container {
		height: 40px;
	}
	.navbar-right-info-icon {
		display: none;
	}

	.flags {
		position: fixed;
		right: 0;
		top: 35px;
	}
}

@media all and (max-width: 550px) {
	.navbar-right-info-container {
		position: fixed;
		left: 0;
		top: 0;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: start;
		-ms-flex-align: start;
		align-items: flex-start;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		z-index: 40;
		padding: 0;
		width: 100vw;
	}
	.navbar-right-info {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}
	.navbar-right-info p {
		margin-right: 10px;
	}
	.navbar-right-info-inner-container {
		height: 40px;
	}
	.navbar-right-info-icon {
		display: none;
	}
}
