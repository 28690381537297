@import url(https://fonts.googleapis.com/css2?family=Teko:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body{
  overflow-x: hidden;
}

a, p, h1, h2, h3, h4, h5, h6{
  font-family: 'Teko', sans-serif;
}

p{
  font-size: 20px;
}

a{
  font-size: 26px;
}

h3, h4, h5, h6{
  font-size: 50px;
}

.spinner-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-container > div{
  border-color: #E50046 !important;
  width: 70px !important;
  height: 70px !important;
  border-width: 7px !important;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.blog-header{
    font-size: 50px;
    color: #B00E35;
}

.blog-paragraph{
    font-size: 20px;
    color: #171B18;
    font-family: 'PT Sans', sans-serif;
}

.text-align-center{
    text-align: center;
}

.text-align-center-blocks h3,
.text-align-center-blocks p{
    text-align: center;
}

.blog-header-blocks h3,
.blog-header-blocks h1,
.blog-header-blocks h2{
    font-size: 50px;
    color: #B00E35;
}

.blog-paragraph-blocks p,
.blog-list-blocks li{
    font-size: 18px;
    color: #171B18;
    font-family: 'PT Sans', sans-serif;
}

.blog-paragraph-blocks a,
.blog-list-blocks a{
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
    color: #E50046;
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6{
    line-height: 45px !important;
}

.blog-paragraph-blocks p{
    margin-top: 10px !important;
}
.landing-baner-outher-container{
    margin-top: 130px;
    width: 100vw;
    height: 670px;
    background-color: #FFF;
    margin-left: calc(-50vw + 600px);
    position: relative;
}

.video-container{
    width: 100%;
    height: 100%;
}

.landing-baner-text-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -280px;
    flex-direction: column;
}

.landing-baner-text{
    max-width: 580px;
    width: 100%;
    padding: 0 20px;
    font-size: 50px;
    text-align: center;
    color: #FFF;
}

.video-container > div{
    width: 100% !important;
    height: 100% !important;
}

.video-container video{
    object-fit: cover;
}

.baner-shadow-container{
    position: absolute;
    width: 100%;
    height: 100%;
}

.baner-shadow-left{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

.baner-shadow-bottom{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5032213569021359) 30%, rgba(0,0,0,0) 75%, rgba(228,0,70,0) 100%);
}

.baner-shadow-right{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

.landing-baner-text-logo{
    color: #FFF;
    font-size: 70px;
}

.landing-baner-text-logo > span{
    font-size: 50px;
}

.landing-baner-logo{
    max-width: 600px;
    width: 90%;
    margin-right: -70px;
    margin-bottom: 25px;
}

@media all and (max-width: 1200px){
    .landing-baner-outher-container{
        margin-left: 0;
    }
}

@media all and (max-width: 550px){
    .landing-baner-outher-container{
        height: 470px;
    }
    .landing-baner-text-container{
        margin-top: -215px;
    }
    .landing-baner-text-container h2{
        line-height: 45px !important;
        font-size: 35px;
    }
    .landing-baner-text-logo{
        font-size: 50px;
        margin-bottom: 0;
    }
    .landing-baner-text-logo > span{
        font-size: 35px;
    }
    .landing-baner-logo{
        margin-bottom: 10px;
        margin-right: -10px;
    }
}
.landing-usluge-outher-container{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: -65px;
}

.landing-usluge-outher-container > div{
    width: 100%;
}

.bg-outher-stripe{
    margin-top: -10px;
    width: 100vw !important;
    margin-left: calc(-50vw + 600px);
}

.bg-outher-stripe-bottom{
    margin-bottom: -180px;
}

@media all and (max-width: 1200px){
    .bg-outher-stripe{
        margin-left: 0;
    }
}

@media all and (max-width: 990px){
    .bg-outher-stripe{
        margin-top: -200px !important;
    }  
}

@media all and (max-width: 575px){
    .landing-usluge-outher-container{
        padding: 0 !important;
    }
}

@media all and (max-width: 563px){
    .d-xs-none{
        display: none !important;
    }
}
.landing-usluga-outher-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.usluga-blob-container{
    background-color: #E50046;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.usluga-blob-title{
    width: 100%;
    padding: 30px 15px 0 15px;
    text-align: center;
    color: #FFF;
    font-size: 33px;
    line-height: 35px !important;
    position: relative;
    z-index: 2;
}

.usluga-blob-icon-container{
    width: 80%;
    height: 120px;
    background-color: #BD0A39;
    margin-bottom: -100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    transition: 150ms;
}

.usluga-blob-icon{
    height: 40px;
    width: 40px;
    object-fit: contain;
    margin-bottom: 14px;
    position: relative;
    z-index: 2;
}

.usluga-background-outher-stripe{
    height: 50px;
    width: 100%;
    position: absolute;
    background-color: #636462;
    /* margin-top: 65px; */
    z-index: -1;
    left: 0;
    display: flex;
    align-items: center;
}

.usluga-background-inner-stripe{
    height: 25px;
    width: 100%;
    position: absolute;
    background-color: #E50046;
    z-index: -1;
    left: 0;
}

.usluga-blob-container:hover .usluga-blob-icon-container{
    transform: scale(3);
    cursor: pointer;
}

.usluga-blob-container:hover .usluga-blog-hover{
    transform: translateY(-68px);
    cursor: pointer;
}

a:hover{
    text-decoration: none !important;
}

.usluga-blog-hover{
    width: 145px;
    height: 120px;
    background-color: #D80342;
    position: relative;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    margin-bottom: -115px;
    z-index: 0;
    transition: 500ms;
}
.landing-blog-text-container{
    width: 100%;
    max-width: 620px;
    /* display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; */
    margin: 0 auto;
    padding: 60px 20px 30px 20px;
}
.linked-info-outher-container{
    margin-top: 150px;
}

/* .linked-info-text-container{
    height: 250px;
} */

.linked-info-text-container-bg{
    height: 100%;
    width: 100vw;
}

.linked-info-text-outher-container{
    width: 100vw;
    background-color: #EAEBEB;
    margin-left: calc(-50vw + 600px);
    border-bottom: 30px solid #CDCFD0;
}

.linked-info-text-image{
    height: 250px;
    width: 310px !important;
    display: flex;
    align-items: center !important;
    justify-content: center;
}

.linked-info-text-image img{
    position: relative;
    bottom: 60px;
}

.linked-info-text-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.linked-info-text-item > h1{
    font-size: 80px;
    color: #B00E35;
    margin: 0 0 -18px 0;
    line-height: 1.2 !important;
}

.linked-info-text-item h3{
    font-size: 36px;
    color: #96999D;
    margin: 0;
    text-align: center;
    line-height: 1.2 !important;
}

.vertical-line{
    height: 200px;
    position: relative;
    top: 26px;
    border-left: 2px solid rgba(90, 92, 90, .3);
    transform: rotate(15deg);
}

.linked-info-featured-image{
    /* margin-bottom: 40px; */
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
    overflow: hidden;
    margin-bottom: -30px;
    position: relative;
    box-shadow: 0 75px 21px -60px rgba(0, 0, 0, .4);
}

.linked-info-featured-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 400px;
}

.linked-info-text-item-border-top{
    border-top: none;
}

.linked-info-featured-image-button-container{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.linked-info-featured-image-button-container a{
    padding: 10px 80px;
    background: rgb(177,14,53);
    background: linear-gradient(0deg, rgba(177,14,53,1) 0%, rgba(211,2,65,1) 100%);
    color: #FFF;
    border-radius: 50px;
    transition: 300ms;
}

.linked-info-featured-image-button-container a:hover{
    background: rgb(177,14,53);
    background: linear-gradient(0deg, rgba(177,14,53,1) 0%, rgba(177,14,53,1) 100%);
    color: #FFF !important;
}

@media all and (max-width: 1200px){
    .linked-info-text-outher-container{
        margin-left: 0;
    }
    .linked-info-text-image{
        align-items: flex-end !important;
    }
    .linked-info-featured-image{
        width: 100vw;
        margin: 0 auto -30px auto;
    }
    .linked-info-text-image img{
        left: 40px;
    }
}

@media all and (max-width: 991px){
    .vertical-line{
        top: 0;
    }
    .linked-info-text-container{
        max-width: 100vw !important;
    }
    .linked-info-text-image{
        align-items: center !important;
    }
    .linked-info-featured-image-button-container a{
        padding: 10px 40px;
    }
}

@media all and (max-width: 767px){
    .linked-info-text-item-border-top{
        border-top: 2px solid rgba(90, 92, 90, .3);
    }
}

@media all and (max-width: 550px){
    .linked-info-featured-image{
        height: 300px;
    }
    .linked-info-text-image img{
        transform: scale(.8);
    }
}
.navbar-outher-container {
	width: 100vw;
	position: fixed !important;
	top: 0;
	left: 0;
	z-index: 999;
}

.navbar-container {
	width: 100%;
	max-width: 1200px;
	height: 130px;
	margin: 0 auto;
	padding: 0 15px;
	background-color: transparent !important;
	display: -ms-grid;
	display: grid;
	-ms-grid-columns: 1fr auto 1fr;
	grid-template-columns: 1fr auto 1fr;
	align-content: center;
	justify-items: start;
}

.navbar-bg {
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	height: 130px;
	width: 100vw;
	z-index: 50;
	border-bottom: 5px solid #e50046;
}

.navbar-navigation-container {
	z-index: 50;
}

.navbar-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 60px;
	padding: 10px;
	background-color: #fff;
	border-radius: 50%;
	/* flex-direction: column; */
	z-index: 50;
}

.navbar-logo img:hover {
	cursor: pointer;
}

.navbar-logo img {
	position: relative;
	z-index: 50;
}

.nav-logo-border {
	position: absolute;
	width: 174px;
	height: 174px;
	/* background-color: red; */
	margin-top: 0px;
	border-radius: 50%;
	box-shadow: 0 0 0 5px #e50046;
}

.nav-logo-overlay-eraser {
	position: fixed;
	top: 0;
	left: 100px;
	height: 125px;
	width: 100vw;
	background-color: #fff;
	z-index: 25;
}

.nav-link {
	color: #002f3b !important;
	padding: 10px 15px !important;
}

.navbar {
	height: 130px;
	top: 57px;
	padding-left: 0 !important;
	z-index: 51;
	padding-right: 0 !important;
	margin-right: -20px;
}

.navbar-right-info-container {
	z-index: 50;
	height: 130px;
	width: 100%;
	min-width: -webkit-max-content;
	min-width: -moz-max-content;
	min-width: max-content;
	position: relative;
	top: 57px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 10px;
}

.navbar-right-info p {
	margin: 0;
	color: #002f3b;
}

.dropdown-menu {
	border: none !important;
	background-color: #e50046 !important;
}

.dropdown-item {
	font-size: 22px;
	padding-right: 100px !important;
	color: #fff !important;
	transition: 300ms;
}

.dropdown-item:hover {
	background-color: #bd0a39 !important;
}

.dropdown-toggle::after {
	transform: translateY(6px);
	color: rgba(150, 153, 157, 0.5) !important;
}

.navbar-right-info-icon {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navbar-right-info-highlighted {
	color: #e50046;
	font-size: 22px;
}

.navbar-right-info-inner-container {
	display: flex;
	align-items: center;
}

.active {
	background-color: none !important;
}

.dropdown-item.active {
	background-color: #e50046 !important;
}

.dropdown-item.active:hover {
	background-color: #bd0a39 !important;
}

.navbar-border-left {
	box-shadow: -14px 0 0 -12px rgba(150, 153, 157, 0.5);
}

.nav-link:hover {
	opacity: 0.8;
}

.flags {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-right: 10px;
}

.flags .flag {
	margin: 5px;
	padding: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eaebeb;
	border-radius: 2px;
}

.flags .flag:nth-child(2) {
	background-color: rgba(227, 0, 70, 0.4);
}

.flags .flag:first-child {
	cursor: pointer;
}

.flags .flag img {
	height: 20px;
	width: 40px;
	object-fit: contain;
}

@media all and (max-width: 990px) {
	.navbar-collapse {
		position: absolute !important;
		top: 130px;
		left: -15px;
	}
	.nav-link {
		padding-left: 20px !important;
		width: 100vw;
		color: #fff !important;
		background-color: #e50046;
		transition: 300ms;
	}
	.navbar {
		z-index: 50;
	}
	.nav-link:hover {
		background-color: #bd0a39 !important;
		opacity: 1;
	}
	.dropdown-menu {
		border-radius: 0 !important;
	}
	.navbar-border-left {
		box-shadow: none;
	}
	.dropdown-toggle::after {
		color: rgba(255, 255, 255, 0.5) !important;
	}
}

@media all and (max-width: 800px) {
	.navbar-logo {
		transform: scale(0.7);
	}
	.nav-logo-overlay-eraser {
		height: 137px;
		top: -2px;
		left: -10px;
		width: 70vw;
	}
	.nav-logo-border {
		box-shadow: 0 0 0 7px #e50046;
	}

	.navbar-right-info-container {
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		z-index: 40;
		padding: 0;
		width: 100vw;
	}
	.navbar-right-info {
		display: flex;
	}
	.navbar-right-info p {
		margin-right: 10px;
	}
	.navbar-right-info-inner-container {
		height: 40px;
	}
	.navbar-right-info-icon {
		display: none;
	}

	.flags {
		position: fixed;
		right: 0;
		top: 35px;
	}
}

@media all and (max-width: 550px) {
	.navbar-right-info-container {
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		align-items: flex-start;
		justify-content: center;
		z-index: 40;
		padding: 0;
		width: 100vw;
	}
	.navbar-right-info {
		display: flex;
	}
	.navbar-right-info p {
		margin-right: 10px;
	}
	.navbar-right-info-inner-container {
		height: 40px;
	}
	.navbar-right-info-icon {
		display: none;
	}
}


.footer-outher-container{
    height: 240px;
    width: 100vw;
    background-color: #EAEBEB;
    margin-left: calc(-50vw + 600px);
    border-bottom: 25px solid #E30046;
    box-shadow: 0 25px 0 #B10E35;
    margin-bottom: 25px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.footer-info-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 25px;
}

.working-hours-container{
    margin-bottom: 20px;
}

.footer-info-container > div{
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-info-container > div > p,
.footer-info-container > div > a{
    padding: 0 10px;
    margin-bottom: 5px;
    font-family: 'PT Sans', sans-serif;
    color: #002F3B !important;
}

.footer-info-container > div > a:hover{
    opacity: .8;
}

.footer-small-text p{
    font-size: 15px;
}

.fotter-shadow-left{
    box-shadow: -7px 0 0 -5px #CDCFD0;
}

.red-color-text{
    color: #E50046;
    font-weight: bold;
}

.footer-popup-trigger:hover{
    opacity: .8;
    cursor: pointer;
}

.footer-popup-container{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    padding-left: 0 !important;
}

.footer-popup-container .modal-dialog{
    width: 95vw;
    height: 90vh;
    max-width: 800px;
    margin: 0 !important;
}

.popup-list-block{
    margin-left: 15px !important;
}

.modal-title h1{
    font-size: 65px !important;
    color: #B00E35 !important;
    font-family: 'Teko', sans-serif;
}

.modal-header{
    padding-bottom: 10px !important;
    padding-top: 30px !important;
}

.modal-body > .blog-paragraph-blocks a{
    display: inline-block !important;
}

@media all and (max-width: 1200px){
    .footer-outher-container{
        margin-left: 0;
    }
}

@media all and (max-width: 580px){
    .fotter-shadow-left{
        box-shadow: none;
    }
    .working-hours-container{
        flex-direction: column;
    }
    .footer-outher-container{
        height: 340px;
    }
    .modal-title h1{
        font-size: 55px !important;
    }
}
.main-outer-container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

@media all and (max-width: 1200px){
    .main-outer-container{
        overflow-x: hidden;
    }
}
.blog-text-container{
    margin-bottom: -40px;
}

.blog-text-container h3{
    line-height: 45px !important;
}

.blog-baner-outher-container{
    width: 100vw;
    height: 500px;
    margin-top: 130px;
    margin-left: calc(-50vw + 600px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.blog-baner-outher-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-baner-title-container{
    width: 100%;
    height: 65px;
    background: rgb(177,14,53);
    background: linear-gradient(270deg, rgba(177,14,53,0) 0%, rgba(227,0,70,0.85) 20%, rgba(227,0,70,0.85) 80%, rgba(211,2,65,0) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -105px;
    margin-bottom: 140px;
    position: relative;
}

.blog-baner-title{
    margin: 0;
    font-size: 50px;
    color: #FFF;
}

.blog-featured-image-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.blog-featured-image{
    width: 100%;
    height: auto;
    max-height: 250px;
    max-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    padding: 0;
    box-shadow: 0 0 0 10px #CECECD;
}

.blog-featured-image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-featured-image-outher-stripe{
    position: absolute;
    height: 80%;
    max-height: 200px;
    width: 60vw;
    background-color: #E50046;
    right: 75px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
}

.blog-featured-image-inner-stripe{
    position: relative;
    height: 55%;
    max-height: 200px;
    width: 60vw;
    background-color: #CECECD;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
}

.blog-text-first-heading{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.blog-text-first-heading h3,
.blog-text-first-heading h2,
.blog-text-first-heading h1{
    margin: 0 0 0 25px;
    padding-top: 10px;
}

.blog-paragraph-blocks span{
    font-weight: bold;
    font-size: 20px;
}

.blog-paragraph-blocks a:hover{
    text-decoration: underline !important;
    color: #E50046;
    opacity: .8;
}

.blog-paragraph-blocks a{
    display: block;
    /* height: 20px; */
    line-height: 20px;
    margin: 10px 0;
}

.blog-list-blocks{
    margin-left: 15px;
    margin: -5px 0 0 0;
    padding: 0 1em;
    list-style: none;
    list-style-type: none;
}

.blog-list-blocks li{
    /* height: 30px; */
    line-height: 20px !important;
}

.blog-list-blocks li::before{
    content: "";
    border-color: transparent #E50046;;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 1em;
    position: relative;
}

.blog-button-blocks{
    width: 100%;
    max-width: 620px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
}

.blog-button-blocks a{
    padding: 10px 60px;
    background: rgb(25,29,26);
    background: linear-gradient(0deg, rgba(25,29,26,1) 0%, rgba(111,111,111,1) 100%); 
    color: #FFF !important;
    border-radius: 50px;
    transition: 300ms;
}

.blog-button-blocks a:hover{
    background: linear-gradient(0deg, #3C3F3D 0%, #3C3F3D 100%);
}

.blog-text-last-block,
.blog-text-last-block-header{
    width: 100%;
    max-width: 620px;
    padding: 0 20px;
}

.blog-text-last-block-header h3,
.blog-text-last-block-header h1,
.blog-text-last-block-header h2{
    text-align: center;
    margin-top: 100px;
}

.blog-text-container p{
    margin: 0;
}

.blog-backgroung-image{
    position: absolute;
    z-index: -5;
    top: 500px;
    right: 0;
    margin-right: calc(-50vw + 600px);
}

.baner-shadow-left-baner{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

.baner-shadow-bottom-baner{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5032213569021359) 30%, rgba(0,0,0,0) 75%, rgba(228,0,70,0) 100%);
}

.baner-shadow-right-baner{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(270deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

/* .blog-outher-container{
    margin-top: 120px !important;
} */

@media all and (max-width: 1200px){
    .blog-baner-outher-container{
        margin-left: 0;
    }
    .blog-backgroung-image{
        top: 630px;
        margin-right: 0;
    }
}

@media all and (max-width: 767px){
    .blog-featured-image-outher-stripe{
        right: 150px;
        width: 80vw;
    }
    .blog-header-blocks h3{
        text-align: center;
    }
    .blog-text-first-heading{
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 10px;
    }
    .blog-backgroung-image{
        right: -300px;
    }   
}

@media all and (max-width: 997px){
    .blog-text-container{
        margin-bottom: 20px;
    }
}

@media all and (max-width: 550px){
    .blog-baner-title-container{
        margin-bottom: 100px;
    }
    .blog-baner-outher-container{
        height: 300px;
    }
    .blog-backgroung-image{
        top: 400px;
        right: -500px;
    }
    .blog-text-first-heading h1,
    .blog-text-first-heading h3{
        margin-left: 10px !important;
    }
}

@media all and (max-width: 564px){
    .blog-outher-container{
        margin-top: 140px !important;
    }
}
.mg-top-40,
.mg-top-40-bottom-title{
    margin-top: -40px;
}

.contact-text-container{
    margin-top: 20px;
}

.contact-small-title{
    color: #E50046;
    font-size: 20px;
    font-family: 'PT Sans', sans-serif;
    margin: 10px 0 0 0;
    font-weight: bold;
}

.sjediste-text p{
    margin: 0;
    line-height: 20px;
    margin: 10px 0;
}

.sjediste-text strong{
    color: #E50046;
    font-size: 22px;
}

.podaci-o-firmi-text{
    font-size: 14px;
    margin-top: 20px;
}

.form-container{
    display: flex;
    flex-direction: column;
}

.form-container label{
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
}

.form-container input,
.form-container textarea{
    padding: 15px;
    background-color: rgba(150, 153, 157, .5);
    border: none;
    outline: none;
    border-radius: 10px;
    resize: none;
    transition: 300ms;
}

.input-error-message{
    margin-bottom: 20px;
    font-family: 'PT Sans', sans-serif;
    font-size: 14px;
    margin-left: 5px;
    color: #E50046;
    opacity: 0;
    transition: 300ms;
}

.form-container textarea{
    height: 240px;
}

.form-button{
    width: 100%;
    max-width: 400px;
    padding: 5px 40px;
    color: white;
    border-radius: 50px;
    border: none;
    outline: none;
    font-size: 36px;
    font-family: 'Teko', sans-serif;
    background: rgb(177,14,53);
    background: linear-gradient(0deg, rgba(177,14,53,1) 0%, rgba(228,0,70,1) 100%);
    margin-top: 15px;
    transition: 300ms;
}

.form-button:hover{
    background: linear-gradient(0deg, #C7083C 0%, #C7083C 100%);
}

.google-maps-container{
    max-height: 400px;
    max-width: 400px;
    height: 800px;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 0 10px #CDCFD0;
    margin-bottom: 40px;
}

.kontaktiraj-nas-container{
    padding-left: 50px;
}

.contact-container{
    margin-bottom: 60px;
}

.error-message-display{
    opacity: 1 !important;
}

.input-error-outline{
    box-shadow: 0 0 0 2px #E50046 !important;
}

.message-sent-container{
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 15px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(145, 255, 175, .95);
    transition: 500ms;
    margin-left: calc(-50vw + 600px);
    /* -webkit-transform: translateZ(0); */
}

.message-sent-container p{
    margin: 0;
    font-family: 'PT Sans', sans-serif;
}

.message-hidden{
    transform: translateY(60px);
}

.message-sent-container div:hover{
    opacity: .8;
    cursor: pointer;
}

.message-invalid{
    background-color: rgba(255, 138, 138, .9)
}

@media all and (max-width: 1200px){
    .message-sent-container{
        margin-left: 0;
    }
}

@media all and (max-width: 980px){
    .mg-top-40-bottom-title{
        margin-top: 40px;
    }
    .kontaktiraj-nas-container{
        padding-left: 15px;
    }
}

@media all and (max-width: 576px){
    .google-maps-container{
        width: 95%;
        margin: 0 auto 40px auto;
    }
}
