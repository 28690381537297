.blog-header{
    font-size: 50px;
    color: #B00E35;
}

.blog-paragraph{
    font-size: 20px;
    color: #171B18;
    font-family: 'PT Sans', sans-serif;
}

.text-align-center{
    text-align: center;
}

.text-align-center-blocks h3,
.text-align-center-blocks p{
    text-align: center;
}

.blog-header-blocks h3,
.blog-header-blocks h1,
.blog-header-blocks h2{
    font-size: 50px;
    color: #B00E35;
}

.blog-paragraph-blocks p,
.blog-list-blocks li{
    font-size: 18px;
    color: #171B18;
    font-family: 'PT Sans', sans-serif;
}

.blog-paragraph-blocks a,
.blog-list-blocks a{
    font-family: 'PT Sans', sans-serif;
    font-size: 20px;
    color: #E50046;
    text-decoration: underline;
}

h1, h2, h3, h4, h5, h6{
    line-height: 45px !important;
}

.blog-paragraph-blocks p{
    margin-top: 10px !important;
}