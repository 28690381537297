.landing-usluge-outher-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: -65px;
}

.landing-usluge-outher-container > div{
    width: 100%;
}

.bg-outher-stripe{
    margin-top: -10px;
    width: 100vw !important;
    margin-left: calc(-50vw + 600px);
}

.bg-outher-stripe-bottom{
    margin-bottom: -180px;
}

@media all and (max-width: 1200px){
    .bg-outher-stripe{
        margin-left: 0;
    }
}

@media all and (max-width: 990px){
    .bg-outher-stripe{
        margin-top: -200px !important;
    }  
}

@media all and (max-width: 575px){
    .landing-usluge-outher-container{
        padding: 0 !important;
    }
}

@media all and (max-width: 563px){
    .d-xs-none{
        display: none !important;
    }
}