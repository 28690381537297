.landing-usluga-outher-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 10px;
}

.usluga-blob-container{
    background-color: #E50046;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    overflow: hidden;
}

.usluga-blob-title{
    width: 100%;
    padding: 30px 15px 0 15px;
    text-align: center;
    color: #FFF;
    font-size: 33px;
    line-height: 35px !important;
    position: relative;
    z-index: 2;
}

.usluga-blob-icon-container{
    width: 80%;
    height: 120px;
    background-color: #BD0A39;
    margin-bottom: -100px;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    -webkit-transition: 150ms;
    -o-transition: 150ms;
    transition: 150ms;
}

.usluga-blob-icon{
    height: 40px;
    width: 40px;
    -o-object-fit: contain;
       object-fit: contain;
    margin-bottom: 14px;
    position: relative;
    z-index: 2;
}

.usluga-background-outher-stripe{
    height: 50px;
    width: 100%;
    position: absolute;
    background-color: #636462;
    /* margin-top: 65px; */
    z-index: -1;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.usluga-background-inner-stripe{
    height: 25px;
    width: 100%;
    position: absolute;
    background-color: #E50046;
    z-index: -1;
    left: 0;
}

.usluga-blob-container:hover .usluga-blob-icon-container{
    transform: scale(3);
    cursor: pointer;
}

.usluga-blob-container:hover .usluga-blog-hover{
    -webkit-transform: translateY(-68px);
        -ms-transform: translateY(-68px);
            transform: translateY(-68px);
    cursor: pointer;
}

a:hover{
    text-decoration: none !important;
}

.usluga-blog-hover{
    width: 145px;
    height: 120px;
    background-color: #D80342;
    position: relative;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    margin-bottom: -115px;
    z-index: 0;
    -webkit-transition: 500ms;
    -o-transition: 500ms;
    transition: 500ms;
}