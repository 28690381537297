.blog-text-container{
    margin-bottom: -40px;
}

.blog-text-container h3{
    line-height: 45px !important;
}

.blog-baner-outher-container{
    width: 100vw;
    height: 500px;
    margin-top: 130px;
    margin-left: calc(-50vw + 600px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
}

.blog-baner-outher-container img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

.blog-baner-title-container{
    width: 100%;
    height: 65px;
    background: rgb(177,14,53);
    background: -webkit-gradient(linear, right top, left top, from(rgba(177,14,53,0)), color-stop(20%, rgba(227,0,70,0.85)), color-stop(80%, rgba(227,0,70,0.85)), to(rgba(211,2,65,0)));
    background: -o-linear-gradient(right, rgba(177,14,53,0) 0%, rgba(227,0,70,0.85) 20%, rgba(227,0,70,0.85) 80%, rgba(211,2,65,0) 100%);
    background: linear-gradient(270deg, rgba(177,14,53,0) 0%, rgba(227,0,70,0.85) 20%, rgba(227,0,70,0.85) 80%, rgba(211,2,65,0) 100%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: -105px;
    margin-bottom: 140px;
    position: relative;
}

.blog-baner-title{
    margin: 0;
    font-size: 50px;
    color: #FFF;
}

.blog-featured-image-container{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    height: 100%;
}

.blog-featured-image{
    width: 100%;
    height: auto;
    max-height: 250px;
    max-width: 250px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    padding: 0;
    -webkit-box-shadow: 0 0 0 10px #CECECD;
            box-shadow: 0 0 0 10px #CECECD;
}

.blog-featured-image img{
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}

.blog-featured-image-outher-stripe{
    position: absolute;
    height: 80%;
    max-height: 200px;
    width: 60vw;
    background-color: #E50046;
    right: 75px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    z-index: -1;
}

.blog-featured-image-inner-stripe{
    position: relative;
    height: 55%;
    max-height: 200px;
    width: 60vw;
    background-color: #CECECD;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
}

.blog-text-first-heading{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}

.blog-text-first-heading h3,
.blog-text-first-heading h2,
.blog-text-first-heading h1{
    margin: 0 0 0 25px;
    padding-top: 10px;
}

.blog-paragraph-blocks span{
    font-weight: bold;
    font-size: 20px;
}

.blog-paragraph-blocks a:hover{
    text-decoration: underline !important;
    color: #E50046;
    opacity: .8;
}

.blog-paragraph-blocks a{
    display: block;
    /* height: 20px; */
    line-height: 20px;
    margin: 10px 0;
}

.blog-list-blocks{
    margin-left: 15px;
    margin: -5px 0 0 0;
    padding: 0 1em;
    list-style: none;
    list-style-type: none;
}

.blog-list-blocks li{
    /* height: 30px; */
    line-height: 20px !important;
}

.blog-list-blocks li::before{
    content: "";
    border-color: transparent #E50046;;
    border-style: solid;
    border-width: 0.35em 0 0.35em 0.45em;
    display: block;
    height: 0;
    width: 0;
    left: -1em;
    top: 1em;
    position: relative;
}

.blog-button-blocks{
    width: 100%;
    max-width: 620px;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 40px;
}

.blog-button-blocks a{
    padding: 10px 60px;
    background: rgb(25,29,26);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(25,29,26,1)), to(rgba(111,111,111,1)));
    background: -o-linear-gradient(bottom, rgba(25,29,26,1) 0%, rgba(111,111,111,1) 100%);
    background: linear-gradient(0deg, rgba(25,29,26,1) 0%, rgba(111,111,111,1) 100%); 
    color: #FFF !important;
    border-radius: 50px;
    -webkit-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
}

.blog-button-blocks a:hover{
    background: -webkit-gradient(linear, left bottom, left top, from(#3C3F3D), to(#3C3F3D));
    background: -o-linear-gradient(bottom, #3C3F3D 0%, #3C3F3D 100%);
    background: linear-gradient(0deg, #3C3F3D 0%, #3C3F3D 100%);
}

.blog-text-last-block,
.blog-text-last-block-header{
    width: 100%;
    max-width: 620px;
    padding: 0 20px;
}

.blog-text-last-block-header h3,
.blog-text-last-block-header h1,
.blog-text-last-block-header h2{
    text-align: center;
    margin-top: 100px;
}

.blog-text-container p{
    margin: 0;
}

.blog-backgroung-image{
    position: absolute;
    z-index: -5;
    top: 500px;
    right: 0;
    margin-right: calc(-50vw + 600px);
}

.baner-shadow-left-baner{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.5)), color-stop(30%, rgba(0,0,0,0)), to(rgba(228,0,70,0)));
    background: -o-linear-gradient(left, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
    background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

.baner-shadow-bottom-baner{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.5)), color-stop(30%, rgba(0,0,0,0.5032213569021359)), color-stop(75%, rgba(0,0,0,0)), to(rgba(228,0,70,0)));
    background: -o-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5032213569021359) 30%, rgba(0,0,0,0) 75%, rgba(228,0,70,0) 100%);
    background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5032213569021359) 30%, rgba(0,0,0,0) 75%, rgba(228,0,70,0) 100%);
}

.baner-shadow-right-baner{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: -webkit-gradient(linear, right top, left top, from(rgba(0,0,0,0.5)), color-stop(30%, rgba(0,0,0,0)), to(rgba(228,0,70,0)));
    background: -o-linear-gradient(right, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
    background: linear-gradient(270deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

/* .blog-outher-container{
    margin-top: 120px !important;
} */

@media all and (max-width: 1200px){
    .blog-baner-outher-container{
        margin-left: 0;
    }
    .blog-backgroung-image{
        top: 630px;
        margin-right: 0;
    }
}

@media all and (max-width: 767px){
    .blog-featured-image-outher-stripe{
        right: 150px;
        width: 80vw;
    }
    .blog-header-blocks h3{
        text-align: center;
    }
    .blog-text-first-heading{
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 40px;
        margin-bottom: 10px;
    }
    .blog-backgroung-image{
        right: -300px;
    }   
}

@media all and (max-width: 997px){
    .blog-text-container{
        margin-bottom: 20px;
    }
}

@media all and (max-width: 550px){
    .blog-baner-title-container{
        margin-bottom: 100px;
    }
    .blog-baner-outher-container{
        height: 300px;
    }
    .blog-backgroung-image{
        top: 400px;
        right: -500px;
    }
    .blog-text-first-heading h1,
    .blog-text-first-heading h3{
        margin-left: 10px !important;
    }
}

@media all and (max-width: 564px){
    .blog-outher-container{
        margin-top: 140px !important;
    }
}