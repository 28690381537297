.landing-baner-outher-container{
    margin-top: 130px;
    width: 100vw;
    height: 670px;
    background-color: #FFF;
    margin-left: calc(-50vw + 600px);
    position: relative;
}

.video-container{
    width: 100%;
    height: 100%;
}

.landing-baner-text-container{
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    margin-top: -280px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}

.landing-baner-text{
    max-width: 580px;
    width: 100%;
    padding: 0 20px;
    font-size: 50px;
    text-align: center;
    color: #FFF;
}

.video-container > div{
    width: 100% !important;
    height: 100% !important;
}

.video-container video{
    -o-object-fit: cover;
       object-fit: cover;
}

.baner-shadow-container{
    position: absolute;
    width: 100%;
    height: 100%;
}

.baner-shadow-left{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: -o-linear-gradient(left, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(0,0,0,0.8)), color-stop(30%, rgba(0,0,0,0)), to(rgba(228,0,70,0)));
    background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

.baner-shadow-bottom{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: -o-linear-gradient(bottom, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5032213569021359) 30%, rgba(0,0,0,0) 75%, rgba(228,0,70,0) 100%);
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0.8)), color-stop(30%, rgba(0,0,0,0.5032213569021359)), color-stop(75%, rgba(0,0,0,0)), to(rgba(228,0,70,0)));
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5032213569021359) 30%, rgba(0,0,0,0) 75%, rgba(228,0,70,0) 100%);
}

.baner-shadow-right{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgb(0,0,0);
    background: -o-linear-gradient(right, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
    background: -webkit-gradient(linear, right top, left top, from(rgba(0,0,0,0.8)), color-stop(30%, rgba(0,0,0,0)), to(rgba(228,0,70,0)));
    background: linear-gradient(270deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 30%, rgba(228,0,70,0) 100%);
}

.landing-baner-text-logo{
    color: #FFF;
    font-size: 70px;
}

.landing-baner-text-logo > span{
    font-size: 50px;
}

.landing-baner-logo{
    max-width: 600px;
    width: 90%;
    margin-right: -70px;
    margin-bottom: 25px;
}

@media all and (max-width: 1200px){
    .landing-baner-outher-container{
        margin-left: 0;
    }
}

@media all and (max-width: 550px){
    .landing-baner-outher-container{
        height: 470px;
    }
    .landing-baner-text-container{
        margin-top: -215px;
    }
    .landing-baner-text-container h2{
        line-height: 45px !important;
        font-size: 35px;
    }
    .landing-baner-text-logo{
        font-size: 50px;
        margin-bottom: 0;
    }
    .landing-baner-text-logo > span{
        font-size: 35px;
    }
    .landing-baner-logo{
        margin-bottom: 10px;
        margin-right: -10px;
    }
}