.main-outer-container{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
}

@media all and (max-width: 1200px){
    .main-outer-container{
        overflow-x: hidden;
    }
}